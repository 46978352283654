@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

* {
  font-family: "Poppins", sans-serif;
}

.truncate-2 {
  display: -webkit-box;
  max-width: 200px;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.active {
  font-weight: 700;
  color: #ffd346;
}

.react-multiple-carousel__arrow {
  z-index: 0 !important;
}

details {
  user-select: none;
}
details summary svg {
  transform: rotate(0deg);
}
details[open] summary svg {
  transform: rotate(-45deg);
}
details[open] summary {
  animation: ease-opacity-t-b 0.5s ease;
}
summary {
  cursor: pointer;
}
svg {
  transition: all 0.3s;
}
/* TO JE TO - TO JE TAJ */
summary::-webkit-details-marker {
  display: none;
}

.btn-review-active {
  background-color: rgba(167, 241, 216, 0.44); /* Green */
  padding: 5px 15px;
  text-align: center;
  border: 2px solid #1e40af;
  text-decoration: none;
  border-radius: 20px;
  font-size: 14px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.btn-review {
  background-color: transparent; /* Green */
  padding: 5px 15px;
  text-align: center;
  border: 2px solid #f2f2f2;
  text-decoration: none;
  border-radius: 20px;
  font-size: 14px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.beetween-container {
  display: flex;
  justify-content: space-between;
}

[type="checkbox"],
[type="radio"] {
  -webkit-appearance: none;
  appearance: none;
  padding: 0;
  -webkit-print-color-adjust: exact;
  color-adjust: exact;
  display: inline-block;
  vertical-align: middle;
  background-origin: border-box;
  -webkit-user-select: none;
  user-select: none;
  flex-shrink: 0;
  height: 1rem;
  width: 1rem;
  color: #1e40af;
  background-color: #fff;
  border-color: #6b7280;
  border-width: 1px;
}

.form-check-input:checked {
  background-color: #1e40af;
  border-color: #1e40af;
}

.nav-tabs .nav-link.active {
  color: #1e40af;
  border-color: #1e40af;
}

ul.pagination {
  display: inline-block;
  padding: 0;
  margin: 0;
  border-radius: 20px;
}

ul.pagination li {
  display: inline;
}

ul.pagination li a {
  color: black;
  float: left;
  padding: 8px 16px;
  text-decoration: none;
  transition: background-color 0.3s;
  background-color: white;
  margin-right: 4px;
  border-radius: 20px;
  border: 1px solid #ddd;
}

ul.pagination li.active a {
  background-color: #4caf50;
  color: white;
  border: 1px solid #4caf50;
}

ul.pagination li.disabled a {
  display: none;
  background-color: #ddd;
}

ul.pagination li:not(.active) a:hover {
  background-color: #ddd;
}

.dotss {
  list-style-type: circle;
}

.accordion-button:not(.collapsed)::after {
  background-image: url("../png/arrow_accordion.png");
  transform: rotate(90deg);
}

.accordion-button::after {
  background-image: url("../png/arrow_accordion.png");
  transform: rotate(0deg);
}

.accordion-item:last-of-type .accordion-button.collapsed {
  border-radius: 100px;
}

.accordion-item:first-of-type .accordion-button.collased {
  border-radius: 100px;
}

.accordion-item:last-of-type .accordion-button {
  border-radius: 100px;
}

.accordion-item:first-of-type .accordion-button {
  border-radius: 100px;
}

.accordion-button:not(.collapsed) {
  background-color: #f9f9f9;
  box-shadow: none;
  color: black;
}

.float {
  position: fixed;
  width: 60px;
  height: 60px;
  bottom: 40px;
  right: 40px;
  background-color: #8029d1;
  color: #fff;
  border-radius: 50px;
  text-align: center;
  box-shadow: 2px 2px 15px #8029d1;
}

.icon-float {
  margin-top: 5px;
  font-size: 30px;
}

.float-message {
  position: fixed;
  z-index: 9999;
  bottom: 120px;
  right: 40px;
  width: 370px;
  height: 520px;
  border-radius: 12px;
  background-color: white;
  box-shadow: 2px 1px 15px #adaeae;
}

.card-chat-group {
  position: relative;
  background-color: white;
  border-radius: 8px;
  margin-top: -15px;
  width: 330px;
  padding: 15px;
  margin-right: auto;
  margin-left: auto;
  box-shadow: 2px 1px 15px #adaeae;
}

.w-356px {
  width: "356px";
}

.radio-jawaban input {
  visibility: hidden;
  position: absolute;
}

.radio-jawaban label:hover {
  cursor: pointer;
}

.radio-jawaban input:checked + label {
  border-color: #38c172;
  background-color: #e3fcec;
}

.scrollbar-w-2::-webkit-scrollbar {
  width: 0.25rem;
  height: 0.25rem;
}

.scrollbar-track-blue-lighter::-webkit-scrollbar-track {
  --bg-opacity: 1;
  background-color: #f7fafc;
  background-color: rgba(247, 250, 252, var(--bg-opacity));
}

.scrollbar-thumb-blue::-webkit-scrollbar-thumb {
  --bg-opacity: 1;
  background-color: #edf2f7;
  background-color: rgba(237, 242, 247, var(--bg-opacity));
}

.scrollbar-thumb-rounded::-webkit-scrollbar-thumb {
  border-radius: 0.25rem;
}

ol {
  list-style: decimal;
  list-style-position: inside;
}

.dot-carousel {
  bottom: -120px !important;
}

.dot-carousel > li {
  padding: 0 !important;
}

.custom-carousel {
  align-items: center;
  max-width: 100%;
}
.custom-carousel > ul {
  align-items: center;
  margin: 8rem 0;
}

.custom-carousel ul li {
  padding: 0 4rem;
  transform: scale(0.7);
}

.custom-carousel > ul > li.react-multi-carousel-item--active {
  transform: scale(1.5);
}

@media only screen and (max-width: 767px) {
  .dot-carousel {
    bottom: -20px !important;
  }

  .custom-carousel > ul {
    align-items: center;
    margin: 3rem 0;
  }

  .custom-carousel ul li {
    padding: 0;
    transform: scale(1);
  }

  .custom-carousel > ul > li > div,
  .custom-carousel > ul > li > a {
    padding: 0;
    margin: 0 1.2rem;
    transform: scale(1);
  }

  .custom-carousel > ul > li.react-multi-carousel-item--active {
    transform: scale(1.3);
  }
}

.swiper-3d .swiper-slide-shadow-left {
  border-radius: 8px;
}

.swiper-3d .swiper-slide-shadow-right {
  border-radius: 8px;
}

.swiper-pagination {
  transition: all;
  position: relative;
  margin-top: 30px;
  margin-bottom: -20px;
}

.swiper-pagination-bullet {
  width: 17px;
  height: 4px;
  text-align: center;
  line-height: 20px;
  font-size: 12px;
  color: #000;
  opacity: 1;
  background: white;
  border-radius: 52px;
}

.swiper-pagination-bullet-active {
  width: 57px;
  color: #fff;
  background: #374151;
}

.slick-slide {
  opacity: 0.8;
  transition: all 500ms;
}

.slick-slide + .slick-active {
  opacity: 1;
}
